@import '../../scss/variables';

.general-modal {
  *::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  *::-webkit-scrollbar {
    width: 7px;
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $dark-gray;
  }
  *::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 20px;
  }
  *::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 20px;
  }
  .MuiPaper-root {
    border-radius: 35px;
    padding: 12px;
    h2 {
      font-family: 'Poppins-regular' !important;
      color: $primary;
    }
  }
}
