@import "../../scss/variables";

.notifications-page {
  color: $primary;
  .notifications-list {
    .notification-item {
      position: relative;
      &:hover {
        background-color: $light-blue !important;
      }
      &:last-of-type {
        border-bottom: none !important;
      }
    }
  }
  .adorment-notifaction {
    .seen-adorment {
      font-size: 1rem;
    }
    position: absolute;
    left: 96%;
    top: 20px;
    color: $secondary;
  }
}
html[dir="rtl"] {
  .adorment-notifaction {
    right: 96%;
  }
}
