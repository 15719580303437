@import "../../../scss/variables";

.MapToolTip {
  border-radius: 4px;
}
.polygon-image {
  border-radius: 4px;
}
.google-maps-container {
  .reset-map-wrapper {
    .map-search-input-wrapper {
      flex-grow: 1;
    }
    .reset-map-btn {
      background-color: transparent;
      border: 1px solid #3d3d3d;
      font-size: 16px;
      &:hover {
        background-color: $white !important;
        color: $black !important;
      }
    }
  }
  .handel-w-h {
    height: 60vh;
    border-radius: 12px 12px 0px 0px !important;
    position: relative !important;
    .map-dimension {
      width: calc(100% - 4%) !important;
      height: calc(100% - 10%) !important;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
