@import "../../scss/variables";

.date-picker-wrapper {
  .date-picker-label {
    font-size: 1rem;
    font-family: "Poppins-Medium" !important;
    color: $primary;
  }
  .custom-date-picker {
    &.MuiFormControl-root {
      border-radius: 16px;
      background-color: $gray-mid;
      &:hover {
        background-color: $gray-light;
      }
      &:focus::placeholder {
        color: $gray;
      }
    }
    &.disbaled {
      background-color: $disbaled-input-color;
    }
    input.MuiInputBase-input {
      &::placeholder {
        color: $gray-placeholder-darker !important;
        font-family: "Poppins-Medium" !important;
      }
    }
  }
}

.MuiButtonBase-root{
  color: $primary !important;
}
.Mui-selected{
  color: white !important;
}

html[dir="rtl"] {
  .MuiCalendarPicker-root {
    .MuiPickersCalendarHeader-labelContainer {
      margin-left: auto !important;
      margin-right: 0 !important;
    }
    .MuiPickersArrowSwitcher-root {
      direction: ltr !important;
    }
  }
} 

.susbend-by-time-modal + div.MuiPickersPopper-root {
  .MuiDayPicker-slideTransition {
    min-height: 192px;
  }
}
