@import '../../../../scss//variables';

.filter-container {
    min-width: 300px;
    width: 100%;
    flex-basis: 20%;
}

.see-more-button{
    border: transparent;
    background-color: transparent;
}
