@import '../../scss/variables';

.employees-page {
  .handle-icon-size {
    width: 0.5rem;
    height: 0.5rem;
  }

  .search-input-wrapper {
    min-width: 35rem;
    @media #{$media-desktop} {
      min-width: 25rem;
    }
    @media #{$media-mobile} {
      min-width: 15rem;
    }
  }
  .w-75 {
    @media #{$media-desktop} {
      width: 100% !important;
    }
  }

  .employees-table-list {
    .table-container {
      padding: 1rem !important;
      border-radius: inherit;
      @media #{$media-mobile} {
        padding: 1rem !important;
      }
    }
    thead {
      tr {
        th:nth-child(1) {
          width: 10% !important;
        }
        th:nth-child(2) {
          width: 16.5% !important;
        }
        th:nth-child(3) {
          width: 15.5% !important;
        }
        th:nth-child(4) {
          width: 12.5% !important;
        }
        th:nth-child(5) {
          width: 12.5% !important;
        }
        th:nth-child(6) {
          width: 12.5% !important;
        }
        th:nth-child(7) {
          width: 12.5% !important;
        }
        th:nth-child(8) {
          width: 12.5% !important;
        }
      }
    }
  }
  .provider-logo {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .status-active {
    background-color: $green;
    color: $success;
  }

  .status-deactivated,
  .status-requested_publishing {
    background-color: $cancelled;
    color: $gray;
  }

  .status-blocked,
  .status-unpublished {
    background-color: $red-shade;
    color: $light-red;
  }

  .status-deactivation_scheduled,
  .status-block_suspended {
    color:$secondary !important;
    background-color: $secondary-bg;
  }

  .hide-icon {
    position: absolute;
  }

  .status {
    position: relative;
    svg {
      opacity: 0 !important;
      padding: 0 !important;
      display: none;
      width: 18px;
      &[aria-expanded='true'] {
        opacity: 1 !important;
      }
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      svg {
        display: inline-block;
        opacity: 1 !important;
      }
    }
    .svg-active * {
      stroke: $success;
    }
    .svg-blocked * {
      stroke: $light-red;
    }
    .svg-deactivated *{
      stroke: $gray;
    }
    .svg-deactivation_scheduled * ,
    .svg-block_suspended * {
      stroke: $secondary;
    }
  }

  .table-icons-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .publish-item {
    grid-column: 4/5;
  }

  @media #{$media-desktop} {
    .employees-header {
      .title-search {
        flex-direction: column !important;
        h4 {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  @media #{$media-tablet} {
    .search-input-wrapper {
      min-width: 100%;
    }
    .filter-label-wrapper {
      width: 100% !important;
    }
    .employees-header {
      flex-direction: column !important;
      align-items: start !important;
      .title-search {
        margin-bottom: 1rem;
        width: 100% !important;
        .search-input-wrapper {
          width: 100% !important;
        }
      }
      .add-btn-wrapper {
        width: 100%;
        text-align: end !important;
      }
    }
  }

  @media #{$media-mobile} {
    .adjust-font-size-small-screen {
      font-size: 14px !important;
    }
    .filter-label-wrapper {
      width: 100% !important;
    }
  }

  @media #{$media-mobile-sm} {
    .filter-label-wrapper {
      width: 100% !important;
    }
    .employees-header {
      .add-btn-wrapper .btn {
        width: 100% !important;
      }
    }
    .add-btn-wrapper .btn {
      width: 100%;
    }
  }
}
