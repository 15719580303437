@import '../../../../scss/_variables.scss';

.add-specialties-services-section {
  .line-separator {
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
    margin: 10px 0;
  }
  .line-separator-96 {
    width: 96%;
    height: 1px;
    background-color: #e5e5e5;
    margin: 10px 0;
  }

  .arrow-bg {
    background-color: #f5f5f5;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
  }

  .service-details-section {
    .label-inline {
      flex-direction: row !important;
      align-items: center;
      gap: 10px;
    }
    .input-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      label {
        flex-shrink: 0;
        margin: 0px !important;
      }
    }
  }
  .h-fit-content {
    height: fit-content;
    gap: 10px;
  }
  .small-icon-bg {
    background-color: #e7e7e7;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .services-section {
    .MuiAccordion-root {
      border-radius: 12px;
      background-color: $search-input-bg;
    }
    .opacity05 {
      opacity: 0.5;
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: $gray;
      border-radius: 10px;
      opacity: 0.2;
    }
    .input-wrapper {
      input {
        background-color: white;
        border-radius: 15px;
      }
    }
  }
  .handle-padding {
    input {
      padding-right: 14px !important;
    }
  }
}
