@import '../../../../scss/variables';

.branch-bookings-page {
    .bookings-table-list {
        .table-container {
            padding: 1rem !important;
            border-radius: inherit;
            @media #{$media-mobile} {
              padding: 1rem !important;
            }
        }
      thead {
        tr {
          th:nth-child(1) {
            width: 10% !important;
          }
          th:nth-child(2) {
            width: 26.5% !important;
          }
          th:nth-child(3) {
            width: 27% !important;
          }
          th:nth-child(4) {
            width: 26.5% !important;
          }
          th:nth-child(5) {
            width: 10% !important;
          }
        }
      }
      .status-container{
        .ongoing-container{
            color:$secondary !important;
            background-color: $secondary-bg;
        }
        .upcoming-container{
            color:$primary !important;
            background-color: $blue-bg;
        }
        .canceled-container{
            color:$coral !important;
            background-color: $coral-bg;
        }
        .pending_payment-container{
            color:$gray !important;
            background-color: $gray-bg;
        }
        .completed-container{
            color:$success !important;
            background-color: $green-bg;
        }
      }
    }
    @media #{$media-desktop} {
      .header-section {
        .title-search {
          flex-direction: column !important;
          h4 {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  
    @media #{$media-tablet} {
      .filter-label-wrapper {
        width: 100% !important;
      }
      .header-section {
        flex-direction: column !important;
        align-items: start !important;
        .title-search {
          margin-bottom: 1rem;
          width: 100% !important;
        }
      }
    }
  
    @media #{$media-mobile} {
      .filter-label-wrapper {
        width: 100% !important;
      }
    }
  
    @media #{$media-mobile-sm} {
      .filter-label-wrapper {
        width: 100% !important;
      }
    }
  }