/* ============= Colors ============= */
$theme-colors: (
  "primary": #3f4752,
  //for all texts
  "secondary": #d19985,
  // for required *
  "bg-primary-light": #3f4752,
  //background-sidebar checkboxes
  "bg-primary-mid": #d19985,
  //background-buttons and notifications
  "black": #3d3d3d,
  // text colors
  "gray": #888d94,
  //login checkbox label color
  "gray-mid": #eff0f6,
  //input background
  "gray-light": #e5e5e5,
  //background-pages
  "gray-flag": #888d94,
  "gray-hr-light": #e0dddd,
  "error": #ba1a1a,
  "success": #43c564,
  "coral": #de5753 ,
  "coral-bg": #ffb4ab33,
  "light-coral":#d199851f,
  "green": #85d1a8,
  "gray-text": #bbc7db,
  "secondary-bg": #d1998526,
  "blue-bg": #bbc7db26,
  "light-blue": #F8F9FC,
);
$primary: #3f4752 !default;
$secondary: #d19985 !default;
$info: #888d94 !default;
$bg-primary-light: #3f4753;
$bg-primary-grid: linear-gradient(90deg, #3f4752, #888d94);
$bg-secondary-grid: linear-gradient(90deg, #d19985, #ffbea3);
$bg-btn-grid: linear-gradient(
  269.18deg,
  #3f4752 9%,
  #63666a 50.05%,
  #3f4752 100%
);

$bg-primary-mid: #e95b25 !default;
$black: #3d3d3d !default;
$white: #ffffff !default;
$gray: #888d94 !default;
$gray-mid: #eff0f6 !default;
$disbaled-input-color: #e0e0e0 !default;
$gray-light: #f6f6f6 !default;
$gray-placeholder: #d6d7e3 !default;
$gray-placeholder-dark: #ababab !default;
$gray-placeholder-darker: #6c6d75 !default;
$error: #ba1a1a !default;
$success: #43c564 !default;
$active: #ffffff !default;
$switch-active: #43c564 !default;
$switch-deactive: #ba1a1a;
$gray-text: #bbc7db !default;
// $orange-text: #e95b25;
$gray-hr: #f5f5f5 !default;
$dark-gray: #dddddd !default;
$upload-text: #95aad3;
$pagination: #1d6187 !default;
$light-red: #ba1a1a !default;
$cancelled: #888d9415 !default;
$error-bg: #f1f1f1 !default;
$second-black: #323232 !default;
$gray-tabs: #929191 !default;
$gray-line: #efefef !default;
$very-light-gray: #444444 !default;
$green: #43c56415 !default;
$red-shade: #ba1a1a15 !default;
$light-orange: #ff7c4a !default;
$search-input-bg: #f0f0f0 !default;
$auth-bg: #FFF9F7 !default;
$search-input-placeholder: #b9bcbe !default;
$table-row-grey: #bebebe !default;
$coral: #de5753 !default;
$coral-bg: #de575326 !default;
$gray-bg:#888d9426 !default;
$green-bg: #43c56426 !default;
$secondary-bg: #d1998526 !default;
$blue-bg: #bbc7db26 !default;
$light-blue: #F8F9FC !default;

$colors: () !default;
$colors: map-merge(
  (
    $primary: $primary,
    $secondary: $secondary,
    $bg-primary-light: $bg-primary-light,
    $bg-primary-mid: $bg-primary-mid,
    $black: $black,
    $white: $white,
    $gray: $gray,
    $gray-mid: $gray-mid,
    $gray-light: $gray-light,
    $error: $error,
  ),
  $colors
);

$primary: $primary;
$secondary: $secondary;
$bg-primary-light: $bg-primary-light;
$bg-primary-mid: $bg-primary-mid;
$black: $black;
$white: $white;
$gray: $gray;
$gray-mid: $gray-mid;
$gray-light: $gray-light;
$error: $error;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "bg-primary-light": $bg-primary-light,
    "bg-primary-mid": $bg-primary-mid,
    "white": $white,
    "black": $black,
    "gray": $gray,
    "gray-mid": $gray-mid,
    "gray-light": $gray-light,
    "error": $error,
  ),
  $theme-colors
);

$media-desktop-lg: "only screen and (min-width : 1200px)";
$media-desktop: "only screen and (max-width : 1200px)";
$media-tablet: "only screen and (max-width : 900px)";
$media-mobile: "only screen and (max-width : 600px)";
$media-mobile-sm: "only screen and (max-width : 320px)";
