@import "../../scss/variables";

.lang-switcher {
  color: $bg-primary-light;
  .lang-label {
    background: $bg-secondary-grid;
    color: white;
    text-align: center;
    padding: 8px 12px;
    @media #{$media-mobile} {
      padding: 3px 9px 5px 9px !important;
    }
  }
}
