@import "./variables";

.error-msg {
  color: $error;
  margin: 5px 0;
}

hr {
  background-color: $primary;
  margin: 20px 0 12px 0;
}

.hint {
  color: $gray;
}

.w-max-content {
  width: max-content;
}

.h-max-content {
  height: max-content;
}

.wrapper-bg {
  background-color: $white;
  padding: 40px 30px;
  border-radius: 16px;
}

.error-border {
  border: 2px solid $error !important;
  border-radius: 16px;
}

.err-asterisk {
  color: $primary;
}

.opacity_7 {
  opacity: 0.7;
}

.opacity-6 {
  opacity: 0.6;
}

.pointer {
  cursor: pointer;
}

.content-wrapper {
  background-color: $white;
  margin: 2.071rem;
}

.second-black {
  color: $second-black;
}

.very-light-gray {
  color: $very-light-gray;
}

.toggle-text {
  color: $gray-text;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-underline {
  text-decoration: underline !important;
}

// To override browser autocomplete inputs styling
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $gray-mid inset !important;
}

input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px $gray-light inset !important;
}

input:-webkit-autofill {
  -webkit-border-radius: 10px !important;
}
