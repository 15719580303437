@import "../../scss/variables";

.input-wrapper {
  label.MuiFormLabel-root {
    font-size: 1rem;
    font-family: "Poppins-Medium" !important;
    color: $primary;
  }
  .MuiFilledInput-root {
    border-radius: 16px;
    background-color: $gray-mid;
    &.Mui-disabled,
    &.Mui-disabled:hover {
      background-color: $disbaled-input-color;
    }
  }
  input.MuiInputBase-input {
    padding: 1rem;
  }
  input.MuiInputBase-input::placeholder {
    color: $gray-placeholder-darker !important;
    font-family: "Poppins-Medium" !important;
  }
  textarea.MuiInputBase-inputMultiline::placeholder {
    color: $gray-placeholder-darker !important;
    font-family: "Poppins-Medium" !important;
  }
  .MuiFilledInput-root:hover {
    background-color: $gray-light;
  }
  input.MuiInputBase-input:focus::placeholder {
    color: $gray;
  }
  .icon-space{
    margin-inline-start: 1rem;
  }
}
