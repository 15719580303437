@import "../../scss/variables";

.icon-wrapper {
  .MuiSwitch-root {
    width: 2.5rem;
    height: 1.5rem;
    padding: 0;
    margin-top: 0.5rem;
    display: flex;
  }
  .MuiButtonBase-root {
    padding: 0.3rem;
    color: $white;
    &.Mui-checked {
      transform: translateX(12px);
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: $switch-active;
      }
    }
  }
  .MuiSwitch-thumb {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.5rem;
    transform: translateY(0px) translateX(2px);
    color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    transition: width 200s;
  }
  .MuiSwitch-track {
    box-sizing: border-box;
    border-radius: 20px;
    opacity: 1;
    background-color: $switch-deactive;
  }
}
