@import '../../../scss/variables';

.side-menu-container {
  background-color: $bg-primary-light;
  height: 100vh;
  border-radius: 0px 15px 15px 0px;
  position: relative;
  padding: 0 1rem;
  .side-menu-list {
    overflow-y: scroll;
    overflow-x: hidden;
    @media #{$media-tablet} {
      overflow-y: unset;
      overflow-x: hidden;
    }
    max-height: calc(100vh - 10rem);
    & > * {
      margin-bottom: 0.5rem;
      display: inherit;
    }
  }
  .side-menu-footer {
    position: absolute;
    bottom: 2%;
    .MuiListItem-root {
      width: 87%;
      &.closed-menu {
        width: 60%;
      }
    }
    .list-item-wrapper.closed-menu {
      .MuiListItemIcon-root {
        margin: 0;
      }
    }
  }
  .MuiListItem-root {
    color: $white !important;
    opacity: 0.5;
  }
  .MuiListItem-root:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: $white !important;
    opacity: 1;
    border-radius: 10px;
  }
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover,
  .MuiListItem-button:hover {
    background-color: $active !important;
    color: $primary !important;
    opacity: 1;
    border-radius: 10px;
    .list-item-label span {
      font-weight: 600;
      color: $primary;
    }
    .MuiSvgIcon-root {
      color: $primary;
    }
  }
  .MuiSvgIcon-root {
    color: $white;
  }
  *::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }

  *::-webkit-scrollbar {
    width: 7px;
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $dark-gray;
  }
  *::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 20px;
  }
  *::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 20px;
  }
  @media #{$media-mobile} {
    height: 100%;
  }

  .list-item-label span {
    white-space: pre-wrap;
    color: white;
  }
  .list-item-wrapper {
    &.closed-menu {
      padding: 0.5rem 0.3rem;
    }
  }
  .child-sub-list {
    padding-left: 2rem;
    & > *:not(:last-child) {
      margin-bottom: 0.5rem;
      display: inherit;
    }
    &.closed-menu {
      padding-left: 0;
    }
    .list-item-wrapper {
      padding: 0.5rem 0 0.5rem 1rem;
      &.closed-menu {
        padding: 0;
      }
    }
  }
}

html[dir='rtl'] {
  .side-menu-container {
    border-radius: 15px 0px 0px 15px;
    .list-item-wrapper {
      &.closed-menu {
        padding: 0.5rem 0.3rem;
      }
    }
    .child-sub-list {
      padding-left: 0;
      padding-right: 2rem;
      &.closed-menu {
        padding-right: 0;
      }
      .list-item-wrapper {
        padding: 1rem 1rem 1rem 0;
        &.closed-menu {
          padding: 0;
        }
      }
    }
  }
}

.desktop-side-menu {
  .MuiPaper-root {
    border: 0;
  }
}
