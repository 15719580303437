@import "../../../scss/variables";

.map-search-input-wrapper {
  position: relative;
  label.MuiFormLabel-root {
    font-size: 1rem;
    font-family: "Inter-Medium" !important;
    color: $primary;
  }
  .MuiFilledInput-root {
    border-radius: 16px;
    background-color: $gray-mid !important;
  }
  input.MuiInputBase-input {
    padding: 1rem;
  }
  input.MuiInputBase-input::placeholder {
    color: $gray-placeholder !important;
    opacity: 1 !important;
    font-family: "Inter-Medium" !important;
  }
  .MuiFilledInput-root:hover {
    background-color: $gray-mid;
  }
  input.MuiInputBase-input:focus::placeholder {
    color: $gray-placeholder;
  }
  .search-options-wrapper {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: $white;
    box-shadow: 1px 1px 5px 0px rgba(84, 84, 84, 0.75);
    border-radius: 8px;
    .search-options-list {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
    }
  }
  .search-option {
    &:hover {
      background-color: $gray-light;
    }
    &:first-of-type {
      border-radius: 8px 8px 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }
  }
  .search-input-wrapper{
    min-width: 20rem;
  }
}
