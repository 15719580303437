@import "../../scss/variables";

.snackbar-container {
  .snackbar.error {
    .MuiSnackbarContent-root {
      background-color: $error;
    }
  }
  .snackbar.success {
    .MuiSnackbarContent-root {
      background-color: $success;
    }
  }
  .MuiSnackbarContent-root {
    justify-content: center;
  }
}
