@import "../../scss/variables";

.btn-upload {
  background-color: $gray-mid !important;
  color: $upload-text !important;
  text-transform: none !important;
  box-shadow: none !important;
}

.logoInput {
  width: 70px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  &.image-postion-right {
    background-position: right;
  }
}

label {
  span[role="button"] {
    min-width: 110px;
    min-height: 100px;
  }

  span.btn-banner {
    @media #{$media-mobile} {
      min-width: 100%;
    }
  }

  .logoInput {
    min-width: 300px;
    min-height: 100px;
  }
  span[role="button"]:hover {
    background-color: #e7e7e7 !important;
  }
  &.grow-child-panner {
    span {
      flex-grow: 0.5;
    }
  }
}
.ovelay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5%;
  svg {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    color: white;
  }
}
