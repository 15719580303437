@import '../../../../scss/_variables.scss';

.view-employees-list {
  .employee {
    background: #f8f9fc;
    border-radius: 15px;
    margin-bottom: 10px;
  }
  .flex-basis-40 {
    flex-basis: 40%;
  }
  .flex-basis-34 {
    flex-basis: 34%;
  }
  .active-container{
    background-color: $green-bg;
    color: $success;
  }
  
  .deactivated-container{
    background-color: $cancelled;
    color: $gray;
  }
  
  .deactivation_scheduled-container{
    color:$secondary !important;
    background-color: $secondary-bg;
  }
}
