@import "../../../scss/_variables.scss";

.login-page {

  .form-login {
    width: 100%;
  }

  .orange-label {
    color: $light-orange;
    @media #{$media-tablet} {
      margin: 0px !important;
      align-self: flex-end;
    }
  }

  .MuiIconButton-edgeEnd {
    margin-right: unset !important;
  }

  .flex-sm-column {
    @media #{$media-tablet} {
      flex-direction: column-reverse;
    }
  }

  .check-box-container div:first-of-type {
    @media #{$media-tablet} {
      align-self: flex-start !important;
    }
  }
}
