@import "../../scss/variables";

.btn {
  text-transform: none !important;
  box-shadow: none !important;
  background: $bg-btn-grid;
}

.btn-secondary {
  background: $bg-secondary-grid;
}

.MuiButton-containedPrimary:hover {
  color: $white !important;
}

.MuiButton-outlinedPrimary {
  background: none;
  color: $secondary !important;
  position: relative !important;
  border: none !important;
}

.MuiButton-outlinedInfo {
  background: none;
  color: $gray !important;
  border-color: $gray !important;
}

.MuiButton-outlinedPrimary::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 1px;
  background: $bg-secondary-grid;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.MuiButton-outlinedInfo {
  &:hover,
  &.Mui-disabled {
    color: $info !important;
    border-color: $info !important;
  }
}

.btn-gray {
  background: $gray !important;
}

// adding classes to override MUI hover and focus styles all over the app

.btn:focus,
.btn:hover {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

.MuiButton-outlinedPrimary:hover,
.MuiButton-outlinedPrimary:focus {
  box-shadow: none !important;
}

.btn-block {
  width: 100%;
}

@media screen and (max-width: 410px) {
  .textdottedSmallScreens {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 9rem;
    white-space: nowrap;
  }
}
