@import "../../../scss/_variables.scss";

.layout-card {
  width: 100%;
  border-radius: 10px;
  background: $white;
  z-index: 1;
  @media #{$media-tablet} {
    width: 100%;
    padding: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
  .subTitle {
    color: $gray;
  }
  .title {
    letter-spacing: 1px;
  }
}

.vectors-section {
  background: $auth-bg;
  width: 42%;
  min-height: 100vh;
}

.component-section {
  width: 65%;
  @media #{($media-mobile)} {
    width: 100%;
    padding: 0 2rem;
  }
  img {
    @media #{($media-mobile)} {
      width: 70%;
    }
    @media #{($media-tablet)} {
      width: 70%;
    }
  }
}
