@import "../../scss/variables";

.stepper-container {
  .MuiStepConnector-root {
    display: none;
    &.custom-stepper {
      display: block;
      top: 50px;
      left: calc(-50% + 40px);
      right: calc(50% + 60px);
    }
  }
  .adjust-font-size-label {
    .MuiStepLabel-label {
      font-size: 14px;
      color: $gray;
    }
  }
  .step-button {
    svg {
      width: 80%;
    }
    &.step-button-inprogress {
      svg {
        width: 77.6px;
        height: 97px;
      }
    }
  }
}
