@import '../../../scss/_variables.scss';

.add-edit-employee-page {
  .form-control:focus {
    box-shadow: none;
  }
  .woring-hours-day {
    width: 100%;
    padding: 19px;
    height: auto;
    background-color: $search-input-bg;
    @media #{$media-mobile} {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
  .working-hours-section {
    div.d-flex.py-1 {
      &:first-of-type {
        .woring-hours-day {
          border-radius: 12px 12px 0px 0px;
        }
      }
      &:last-of-type {
        .woring-hours-day {
          border-radius: 0px 0px 12px 12px;
        }
      }
    }
  }
  .flex-basis-25 {
    flex-basis: 25%;
  }
  .react-tel-input {
    input {
      width: 100%;
      animation-duration: 10ms;
      border-radius: 16px;
      height: 48px;
      border-color: transparent;
      background-color: $cancelled;
      color: $info;
    }
    .flag-dropdown {
      border-color: #dbdbdb;
      border-radius: 12px 0 0 12px !important;
      .selected-flag {
        width: 42px;
        border-radius: 12px 0 0 12px !important;
        cursor: not-allowed !important;
      }
    }
  }
  .react-tel-input.phone-arabic-dir {
    .flag-dropdown {
      border-radius: 0 12px 12px 0 !important;
      .selected-flag {
        width: 50px;
        border-radius: 0 12px 12px 0 !important;
        cursor: not-allowed !important;
        .flag {
          left: 6px;
        }
      }
    }
    .form-control {
      padding-right: 57px;
      padding-left: 0px;
    }
  }
  .time-slots {
    .timePicker {
      border-radius: 12px;
      background-color: white;
      label {
        color: $primary;
      }
      input {
        padding-top: 15px;
        padding-bottom: 15px;
        width: 80px;
      }
    }
    .reverse-style-ar {
      div.Mui-error {
        flex-direction: row-reverse;
      }
    }
    .add-btn-wrapper {
      button {
        padding: 9px !important;
        min-width: unset !important;
      }
    }
    .copy-working-hours {
      background-color: white;
      border-radius: 15px;
      width: fit-content;
      padding: 5px 12px;
    }
  }
  .location-section {
    .MuiAccordion-root {
      border-radius: 12px;
      background-color: $search-input-bg;
    }
    .opacity05 {
      opacity: 0.5;
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: $gray;
      border-radius: 10px;
      opacity: 0.2;
    }
    .select-bg-white {
      p {
        flex-basis: 100%;
      }
      label {
        flex-basis: 20%;
      }
      .input-wrapper {
        flex-grow: 1;
        input {
          background-color: white;
        }
      }
    }
    .select-bg-white.input-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      @media #{$media-mobile} {
        flex-direction: column;
        align-items: flex-start;
      }
      label {
        flex-basis: 20%;
      }
      .MuiInputBase-fullWidth {
        flex-basis: 80%;
      }
      input {
        background-color: white;
        border-radius: 15px;
      }
      p {
        flex-basis: 100%;
        margin-inline-start: 21%;
      }
    }
  }
  .copy-style {
    background-color: white;
    padding: 10px;
    border-radius: 16px;
  }
}
