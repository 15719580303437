@import '../../../../scss/_variables.scss';

.branch-details {
  .branch-info {
    flex-basis: 60%;
  }

  .branch-image {
    flex-basis: 40%;
  }

  .banner {
    height: 95%;
    min-height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    margin-bottom: .5rem !important;
  }

  .line-separator {
    width: 100%;
    height: 1px;
    background-color: #eceef3;
    margin: 30px 0;
  }
}
