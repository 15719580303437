@font-face {
  font-family: "Poppins-Light";
  src: url("../assets/fonts/Poppins-Light.ttf") format("ttf"),
    url("../assets/fonts/Poppins-Light.woff") format("woff"),
    url("../assets/fonts/Poppins-Light.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Light.eot") format("eot"),
    url("../assets/fonts/Poppins-Light.svg#Poppins-Light") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../assets/fonts/Poppins-Regular.ttf") format("ttf"),
    url("../assets/fonts/Poppins-Regular.woff") format("woff"),
    url("../assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Regular.eot") format("eot"),
    url("../assets/fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/Poppins-Medium.ttf") format("ttf"),
    url("../assets/fonts/Poppins-Medium.woff") format("woff"),
    url("../assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Medium.eot") format("eot"),
    url("../assets/fonts/Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../assets/fonts/Poppins-SemiBold.ttf") format("ttf"),
    url("../assets/fonts/Poppins-SemiBold.woff") format("woff"),
    url("../assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Poppins-SemiBold.eot") format("eot"),
    url("../assets/fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins-Bold.ttf") format("ttf"),
    url("../assets/fonts/Poppins-Bold.woff") format("woff"),
    url("../assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Bold.eot") format("eot"),
    url("../assets/fonts/Poppins-Bold.svg#Poppins-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Italic";
  src: url("../assets/fonts/Poppins-Italic.ttf") format("ttf"),
    url("../assets/fonts/Poppins-Italic.woff") format("woff"),
    url("../assets/fonts/Poppins-Italic.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Italic.eot") format("eot"),
    url("../assets/fonts/Poppins-Italic.svg#Poppins-Italic") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Light";
  src: url("../assets/fonts/Cairo-Light.eot");
  src: url("../assets/fonts/Cairo-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Cairo-Light.ttf") format("ttf"),
    url("../assets/fonts/Cairo-Light.woff") format("woff"),
    url("../assets/fonts/Cairo-Light.woff2") format("woff2"),
    url("../assets/fonts/Cairo-Light.svg#Cairo-Light") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Regular";
  src: url("../assets/fonts/Cairo-Regular.eot");
  src: url("../assets/fonts/Cairo-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Cairo-Regular.ttf") format("ttf"),
    url("../assets/fonts/Cairo-Regular.woff") format("woff"),
    url("../assets/fonts/Cairo-Regular.woff2") format("woff2"),
    url("../assets/fonts/Cairo-Regular.svg#Cairo-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Medium";
  src: url("../assets/fonts/Cairo-Medium.eot");
  src: url("../assets/fonts/Cairo-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Cairo-Medium.ttf") format("ttf"),
    url("../assets/fonts/Cairo-Medium.woff") format("woff"),
    url("../assets/fonts/Cairo-Medium.woff2") format("woff2"),
    url("../assets/fonts/Cairo-Medium.svg#Cairo-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo-Bold";
  src: url("../assets/fonts/Cairo-Bold.eot");
  src: url("../assets/fonts/Cairo-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Cairo-Bold.ttf") format("ttf"),
    url("../assets/fonts/Cairo-Bold.woff") format("woff"),
    url("../assets/fonts/Cairo-Bold.woff2") format("woff2"),
    url("../assets/fonts/Cairo-Bold.svg#Cairo-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
