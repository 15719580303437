@import '../../scss/variables';

.transactions-page {
  .header-section {
    .title-search {
      .search-label-wrapper{
        width: 40% !important;
      }
    }
  }
    .transactions-table-list {
        .table-container {
            padding: 1rem !important;
            border-radius: inherit;
            @media #{$media-mobile} {
              padding: 1rem !important;
            }
        }
        .appoinment-id-contanier {
          color: $gray;
          &:hover {
            color: $primary;
          }
        }
        thead {
          tr {
            th:nth-child(1) {
              width: 20% !important;
            }
            th:nth-child(2) {
              width: 20% !important;
            }
            th:nth-child(3) {
              width: 20% !important;
            }
            th:nth-child(4) {
              width: 20% !important;
            }
            th:nth-child(5) {
              width: 20% !important;
            }
          }
        } 
    }
    @media #{$media-desktop} {
      .header-section {
        .title-search {
          flex-direction: column !important;
          h4 {
            margin-bottom: 0.5rem;
          }
          .search-label-wrapper{
            margin: 0.5rem 0;
            width: 100% !important;
          }
        }
      }  
    }

    @media #{$media-desktop-lg} {
      .filter-label-wrapper {
        width: 100% !important;
      }
      .header-section {
        flex-direction: column !important;
        align-items: start !important;
        .title-search {
          h4 {
            margin-bottom: 0.5rem;
          }
          margin-bottom: 0.5rem;
          width: 80% !important;
          .search-label-wrapper{
            width: 50% !important;
          }
        }
      }
    }
  
    @media #{$media-tablet} {
      .filter-label-wrapper {
        width: 100% !important;
      }
      .header-section {
        flex-direction: column !important;
        align-items: start !important;
        .title-search {
          h4 {
            margin-bottom: 0.5rem;
          }
          margin-bottom: 1rem;
          width: 100% !important;
          .search-label-wrapper{
            width: 100% !important;
          }
        }
      }
      
    
    }
  
    @media #{$media-mobile} {
      .filter-label-wrapper {
        width: 100% !important;
      }
      .header-section {
        .title-search {
          .search-label-wrapper{
            width: 100% !important;
          }
        }
      }
      
    }
  
    @media #{$media-mobile-sm} {
      .filter-label-wrapper {
        width: 100% !important;
      }
      .header-section {
        .title-search {
          .search-label-wrapper{
            width: 100% !important;
          }
        }
      }
    }
  }