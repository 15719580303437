@import "../../../scss/variables";

.nav-bar.MuiAppBar-root {
  background-color: $white !important;
  padding: 1rem 0;
  .page-title {
    @media #{$media-tablet} {
      font-size: 1.7rem !important;
    }
    @media #{$media-mobile} {
      font-size: 1.5rem !important;
    }
    @media #{$media-mobile-sm} {
      font-size: 1.3rem !important;
    }
  }
  .notification-icon-wrapper {
    .notifications-count {
      background-color: $secondary;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      right: 0;
    }
  }
}

html[dir="rtl"] {
  header.nav-bar {
    right: auto;
    left: 0;
    margin-left: 0;
    .mobile-toggle-icon {
      margin-left: 0rem;
    }
  }
}

.adorment-notifaction {
  color: $secondary;
  .seen-adorment {
    width: 1rem;
  }
}
