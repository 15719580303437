.bread-crumb-link {
  font-size: 21px;
  font-weight: 600;
}
.active-bread-crumb-link {
  font-size: 21px;
  font-weight: 600;
  pointer-events: none;
}
.bread-crumb-icon {
  font-size: 30px !important;
}
.MuiBreadcrumbs-separator{
  color: #9E9E9E;
  font-size: 1.3rem;
}
