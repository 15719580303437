@import "../../../scss//variables";

.filter-date-picker-wrapper {
  position: relative;
  .MuiFormControl-root .MuiTextField-root.custom-date-picker {
    background-color: $search-input-bg !important;
    
    padding-inline-start: 1rem;
    padding-inline-end: 1.563rem;
    &:hover {
      background-color: $gray-mid;
    }
    &:focus::placeholder {
      color: $primary;
    }
    &.disbaled {
      background-color: $disbaled-input-color;
    }
  }
  input.MuiInputBase-input.MuiOutlinedInput-input {
    &::placeholder {
      color: $primary !important;
    }
    padding: 1.5rem 1rem 0.5rem 1rem !important;
    max-height: 1rem !important;
  }
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
    margin: 5px 8px !important;
    padding: 2px;
    color: $primary !important;
  }
  label {
    color: $primary !important; 
    top: -5px;
    &.MuiInputLabel-shrink {
        top: 0 !important;
    }
    &.Mui-focused {
      color: $primary;
    }
  }
  div.MuiInputBase-root.MuiOutlinedInput-root {
    background-color: $search-input-bg;
    border-radius: 16px;
    padding-top: 1px;
  }
  .close-btn {
    position: absolute;
    inset-inline-end: 2rem;

    .clear-icon {
      color: $primary;
    }
  }
}

html[dir="rtl"] {
  .filter-date-picker-wrapper {
    label {
      right: 25px;
      &.MuiInputLabel-shrink {
        right: 10px !important;
      }
    }
    .MuiCalendarPicker-root {
      .MuiPickersCalendarHeader-labelContainer {
        margin-left: auto !important;
        margin-right: 0 !important;
      }

      .MuiPickersArrowSwitcher-root {
        direction: ltr !important;
      }
    }
  }
}

.susbend-by-time-modal + div.MuiPickersPopper-root {
  .MuiDayPicker-slideTransition {
    min-height: 12rem;
  }
}