@import "../../../scss/_variables.scss";

.setup-profile-page {
    .orange-label {
        color: $light-orange;
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
    }
    .MuiIconButton-edgeEnd {
        margin-right: unset !important;
      }
}
