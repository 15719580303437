@import "../../scss/variables";

.layout {
  margin: 0 !important;
}
.sideMenuBar {
  padding: 0 !important;
}
.pages-container {
  padding: 0 !important;
}
.children-container {
  background-color: $gray-mid;
  height: 100%;
}
.MuiPaper-root.MuiDrawer-paper {
  overflow: unset !important;
  background: transparent;
}
