@import '../../../../scss/_variables.scss';

.view-services-details {
  .service {
    background: #f8f9fc;
    border-radius: 15px;
    margin-bottom: 10px;
    .css-1t7k99c-MuiPaper-root-MuiAccordion-root{
      background-color: unset;
    }
  }
}
