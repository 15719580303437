@import '../../../scss/variables';

.reject-reason-container {
    background-color: $coral-bg;
    border-radius: 12px;
    color: $coral;
}
.see-more-btn {
    border: transparent ;
    background-color: transparent;
    color: $coral;
    padding: 0;
}

@media #{$media-desktop-lg} {
    .buttons-contanier{
        justify-content: end;
        width: 50% !important;
    }
}

@media #{$media-mobile-sm} {
    .buttons-contanier{
        width: 100% !important;
    }
}


