#additional-actions1-header {
  min-height: auto !important;
}
.MuiAccordionSummary-expandIcon.Mui-expanded,
#additional-actions1-header div.MuiAccordionSummary-expandIconWrapper {
  transform: none !important;
}
.MuiAccordionSummary-content {
  cursor: auto;
}
.filter-wrapper label {
  top: 5px;
}
#additional-actions1-content div.MuiAccordionDetails-root {
  padding-left: unset;
  padding-right: unset;
}
.MuiFormControlLabel-label{
  width: 100%;
}
.cursor-default{
  cursor: default !important;
}
