@import "../../scss/variables";

.table-container {
  padding: 0.5rem 5rem !important;
  text-align: center !important;

  .MuiTableHead-root {
    border-bottom: 2px solid $gray-hr !important;
  }
  th {
    color: $gray-text !important;
    text-align: center;
    padding: 10px 16px;
  }
  td {
    border-bottom: none !important;
  }
  tbody {
    td {
      font-size: 1.2rem !important;
      color: $primary !important;
      text-align: center;
      &.start {
        text-align: start;
      }
    }
    tr.dimmed td {
      color: $table-row-grey !important;
    }
  }
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

html[dir="rtl"] {
  .table-container {
    .MuiTableCell-root {
      text-align: start;
    }
  }
}
