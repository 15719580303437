html {
  font-size: 14px;
  font-family: "Poppins-Regular", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $primary;
  }
}

.font-cairo-regular {
  font-family: "Cairo-Regular" !important;
}

.font-cairo-medium {
  font-family: "Cairo-Medium" !important;
}

.font-cairo-semibold {
  font-family: "Cairo-Semibold" !important;
}

.font-cairo-bold {
  font-family: "Cairo-Bold" !important;
}

.font-regular {
  font-family: "Poppins-Regular" !important;
}

.font-medium {
  font-family: "Poppins-Medium" !important;
}

.font-semibold {
  font-family: "Poppins-SemiBold" !important;
}

.font-bold {
  font-family: "Poppins-Bold" !important;
}

.font-light {
  font-family: "Poppins-Light" !important;
}
.font-italic {
  font-family: "Poppins-Italic" !important;
}

.text-white {
  color: $white !important;
}

.text-red {
  color: $light-red !important;
}

.text-dark {
  color: $primary;
}

.text-gray {
  color: $gray !important;
}

.text-dark-blue {
  color: $bg-primary-light !important;
}
.page-title {
  color: $primary !important;
}
.text-secondary {
  color: $secondary !important;
}
