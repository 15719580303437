@import '../../../../scss/_variables.scss';

.bg-section {
  background-color: $search-input-bg;
  border-radius: 15px;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: $gray;
  border-radius: 10px;
  opacity: 0.2;
}
.flex-basis-15 {
  flex-basis: 15%;
}
.custmo-color {
  background-color: $search-input-bg;
  border-radius: 15px;
  padding: 10px 10px;
}
.flex-basis-40 {
  flex-basis: 40%;
}
.flex-basis-34 {
  flex-basis: 34%;
}
.active-container{
  background-color: $green-bg;
  color: $success;
}
.deactivated-container{
  background-color: $cancelled;
  color: $gray;
}
.deactivation_scheduled-container{
  color:$secondary !important;
  background-color: $secondary-bg;
}
.status-container{
  width: 70px;
}
