@import "../../../scss/variables";

.coverage-areas-page {
  .google-map-section {
    min-height: 65vh;
    @media #{$media-mobile} {
      min-height: 78vh;
    }
  }
  .no-gutter {
    --bs-gutter-x: 0;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    border-radius: 0px 0px 12px 12px;
    margin-bottom: 20px;
  }
}
