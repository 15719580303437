@import "../../../scss/_variables.scss";

.orange-label {
  color: $light-orange;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

h1.title.fsize-32 {
  @media #{$media-mobile} {
    font-size: 1.8rem !important;
  }
}
