@import '../../scss/variables';

.single-select-wrapper {
  label {
    color: $primary !important;
    font-family: "Poppins-Medium" !important;
  }
  input {
    background-color: $gray-mid;
    border: none !important;
    border-radius: 16px !important;
    padding: 0.714rem;
    width: 100% !important;
    &.Mui-disabled,
    &.Mui-disabled:hover {
      background-color: $disbaled-input-color;
    }
  }
  input::placeholder {
    color: $gray-placeholder !important;
    font-family: 'Poppins-Medium' !important;
  }
  input:focus {
    outline: none !important;
  }
  input:hover {
    background-color: $gray-light;
  }
  input:focus::placeholder {
    color: $gray;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0 !important;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child {
    padding: 1rem;
  }
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    content: none !important;
  }
  .MuiAutocomplete-endAdornment {
    right: 1rem;
  }
}
.multiple-select-wrapper {
  label {
    color: $primary !important;
  }
  .removed-placeholder {
    input.MuiInputBase-input::placeholder {
      color: transparent !important;
    }
  }
  .MuiInputBase-root {
    padding-left: 0.5rem !important;
  }
  input.MuiInputBase-input::placeholder {
    color: $gray-placeholder-darker !important;
    font-family: 'Poppins-Medium' !important;
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    flex-wrap: wrap;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}
.Mui-focused {
  border-color: transparent;
}

.rtl-icons-direction-handel {
  div.MuiOutlinedInput-root {
    div.MuiAutocomplete-endAdornment {
      left: 9px;
      right: unset;
    }
  }
}

.multi-select-style {
  div.MuiInputBase-root {
    padding: 6px 0px;
    background-color: #eff0f6;
    flex-wrap: unset;
    border-radius: 16px;
  }
}

.flex-basis-50 {
  flex-basis: 50%;
}

html[dir='rtl'] {
  .multiple-select-wrapper {
    .MuiInputBase-root {
      padding-right: 0.5rem !important;
    }
  }
  .MuiAutocomplete-tag {
    svg {
      margin: 0 0 0 5px !important;
    }
  }
}

.custom-select-style {
  .MuiFormControl-fullWidth {
    background: $gray-mid;
    border-radius: 16px;
    input {
      &hover {
        background: $gray-mid !important;
      }
    }
  }
}
