@import '../../../../scss/_variables.scss';

.view-reviews-details-page {
  .reviews-table-list {
    .table-container {
      padding: 1rem 1.5rem !important;
      border-radius: inherit;
      @media #{$media-mobile} {
        padding: 1rem !important;
      }
    }
  }
  thead {
    tr {
      th:nth-child(1) {
        width: 25% !important;
        text-align: start;
      }
      th:nth-child(2) {
        width: 30% !important;
      }
      th:nth-child(3) {
        width: 30% !important;
      }
      th:nth-child(4) {
        width: 15% !important;
      }
    }
  }
}
