@import "../../scss/variables";

.pagination-wrapper {
  .MuiButtonBase-root {
    color: $pagination !important;
  }
  .MuiPaginationItem-page.Mui-selected,
  .MuiPaginationItem-page.Mui-selected:hover {
    background-color: transparent !important;
    color: $bg-primary-light;
    font-weight: 600;
  }
  .MuiPaginationItem-root {
    margin: 0 !important;
  }
}
