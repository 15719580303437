@import '../../../scss//variables';
.booking-details-container{
    .report-container{
        background-color: $coral-bg;
        color: $coral;
    }
    .data-container{
        border-radius: 12px;
    }
    .status-card{
        background-color: #bbc7db26;
        border-radius: 11px;
        color: $primary
    }
    .service-card{
        background: #F8F9FC;
        border-radius: 15px; 
    }

    .status-container{
        .ongoing-container{
            color:$secondary !important;
            background-color: $secondary-bg;
        }
        .upcoming-container{
            color:$primary !important;
            background-color: $blue-bg;
        }
        .canceled-container{
            color:$coral !important;
            background-color: $coral-bg;
        }
        .pending_payment-container{
            color:$gray !important;
            background-color: $gray-bg;
        }
        .completed-container{
            color:$success !important;
            background-color: $green-bg;
        }
    }

}
